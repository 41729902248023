// This file is part of the Cloudillo Platform.
// Copyright (C) 2024  Szilárd Hajba
//
// Cloudillo is free software: you can redistribute it and/or modify
// it under the terms of the GNU Lesser General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Lesser General Public License for more details.
//
// You should have received a copy of the GNU Lesser General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
import * as React from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { LuX as IcClose } from 'react-icons/lu';
import { delay } from '@cloudillo/base';
export function mergeClasses(...classes) {
    return classes.filter(c => c).join(' ');
}
/**********/
/* Button */
/**********/
export function Button({ className, onClick, primary, secondary, accent, link, children, ...props }) {
    const [cls, setCls] = React.useState('');
    const variantClass = primary ? 'primary'
        : secondary ? 'secondary'
            : accent ? 'accent'
                : undefined;
    async function handleClick(evt) {
        evt.preventDefault();
        setCls(' clicked');
        await delay(200);
        setCls('');
        onClick?.(evt);
    }
    return React.createElement("button", { ...props, className: mergeClasses(link ? 'c-link' : 'c-button', variantClass, cls, className), onClick: handleClick }, children);
}
export function Popper({ className, icon: Icon, label, children, ...props }) {
    const [popperRef, setPopperRef] = React.useState(null);
    const [popperEl, setPopperEl] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const { styles: popperStyles, attributes } = usePopper(popperRef, popperEl, {
        placement: 'bottom-start',
        strategy: 'fixed'
    });
    return React.createElement("details", { className: mergeClasses('c-nav-link', className), open: isOpen, onClick: () => setIsOpen(!isOpen) },
        React.createElement("summary", { ref: setPopperRef }, label),
        isOpen && createPortal(React.createElement("div", { ref: setPopperEl, style: popperStyles.popper, ...attributes.popper }, children), document.getElementById('popper-container')));
}
/****************/
/* FcdContainer */
/****************/
function FcbContainer({ className, children }) {
    return React.createElement("main", { className: mergeClasses('c-container h-100', className) },
        React.createElement("div", { className: "row h-100" }, children));
}
function FcbFilter({ className, isVisible, hide, children }) {
    return React.createElement("div", { className: mergeClasses('c-vbox sm-hide hide-left col-md-4 col-lg-3 h-100 overflow-y-auto', className, isVisible && 'show'), onClick: hide },
        React.createElement(Button, { link: true, className: "pos absolute top-0 right-0 m-1 z-4 md-hide lg-hide", onClick: hide },
            React.createElement(IcClose, null)),
        React.createElement("div", { className: "w-100", onClick: evt => evt.stopPropagation() }, children));
}
function FcbContent({ className, children }) {
    return React.createElement("div", { className: mergeClasses('c-vbox col col-md-8 col-lg-6 h-100 overflow-y-auto', className) }, children);
}
function FcbDetails({ isVisible, className, hide, children }) {
    return React.createElement("div", { className: mergeClasses('c-vbox sm-hide md-hide hide-right col-lg-3 h-100 overflow-y-auto', className, isVisible && 'show'), onClick: hide },
        React.createElement(Button, { link: true, className: "pos absolute top-0 right-0 m-1 z-4 md-hide lg-hide", onClick: hide },
            React.createElement(IcClose, null)),
        React.createElement("div", { className: "z-1 w-100 h-min-100", onClick: evt => evt.stopPropagation() }, children));
}
export const Fcb = { Container: FcbContainer, Filter: FcbFilter, Content: FcbContent, Details: FcbDetails };
export function UserPicture({ className, user, small }) {
    return React.createElement("div", { className: "c-profile-card" }, user.profilePic
        ? React.createElement("img", { className: 'picture' + (small ? ' small' : ''), src: `https://cl-o.${user.tag}/${user.profilePic}` })
        : React.createElement("svg", { className: 'picture' + (small ? ' small' : ''), viewBox: "0 0 24 24", fill: "none" },
            React.createElement("path", { d: "M12 22.01C17.5228 22.01 22 17.5329 22 12.01C22 6.48716 17.5228 2.01001 12 2.01001C6.47715 2.01001 2 6.48716 2 12.01C2 17.5329 6.47715 22.01 12 22.01Z", fill: "#ADB3BA" }),
            React.createElement("path", { d: "M12 6.93994C9.93 6.93994 8.25 8.61994 8.25 10.6899C8.25 12.7199 9.84 14.3699 11.95 14.4299C11.98 14.4299 12.02 14.4299 12.04 14.4299C12.06 14.4299 12.09 14.4299 12.11 14.4299C12.12 14.4299 12.13 14.4299 12.13 14.4299C14.15 14.3599 15.74 12.7199 15.75 10.6899C15.75 8.61994 14.07 6.93994 12 6.93994Z", fill: "#292D32" }),
            React.createElement("path", { d: "M18.7807 19.36C17.0007 21 14.6207 22.01 12.0007 22.01C9.3807 22.01 7.0007 21 5.2207 19.36C5.4607 18.45 6.1107 17.62 7.0607 16.98C9.7907 15.16 14.2307 15.16 16.9407 16.98C17.9007 17.62 18.5407 18.45 18.7807 19.36Z", fill: "#292D32" })));
}
export function IdentityTag({ className, tag = '-' }) {
    const segments = tag.match(/([a-zA-Z\.]+|[^a-zA-Z\.]+)/g) || [];
    return React.createElement(React.Fragment, null, segments.map((segment, i) => React.createElement("span", { key: i, className: /[^a-zA-Z\.]/.test(segment) ? 'warn' : undefined }, segment)));
}
export function UserCard({ className, user }) {
    return React.createElement("div", { className: mergeClasses('c-profile-card', className) },
        React.createElement("img", { className: "picture", src: `https://cl-o.${user.tag}/${user.profilePic}` }),
        React.createElement("div", { className: "body" },
            React.createElement("h4", { className: "name" }, user.name),
            React.createElement("div", { className: "tag" },
                React.createElement(IdentityTag, { tag: user.tag }))));
}
// vim: ts=4
