export function ok(t) { return { ok: t }; }
export function err(error) { return { err: error }; }
export function isOk(r) { return 'ok' in r; }
export function isErr(r) { return 'err' in r; }
// Other helper funcs //
////////////////////////
export function isEmptyObject(obj) {
    if (typeof obj !== 'object' || obj === null)
        return false;
    for (const k in obj)
        return false;
    return true;
}
// vim: ts=4
